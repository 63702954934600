jQuery(document).ready(function($) {
	/* Mobile menu */
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($( ".mobile-navigation-menu" ).hasClass( "mm-opened" )) {
			$(".mobile-navigation-menu").data( "mmenu" ).close();
		}
		else {
			$(".mobile-navigation-menu").data( "mmenu" ).open();
		}
	});

	/* FitVids */
	if ($("body").innerWidth() <= 767 ) {
		$(".page-wrapper").fitVids({ ignore: "nofit" });
		$(".inside-page-wrapper").fitVids({ ignore: "nofit" });
	}

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .main-nav-text").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".main-nav-dd-widget").removeClass("open");
				event.preventDefault();

				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".main-nav-dd-widget").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	/* eslint-disable */
	/* Webflow: Interactions: Init */
	Webflow.require('ix').init([
	{"slug":"main-nav-dd-smooth-open","name":"Main Nav DD Smooth Open","value":{"style":{},"triggers":[{"type":"dropdown","stepsA":[{"opacity":0,"height":"0px"},{"opacity":1,"height":"auto","transition":"opacity 350ms ease 0, height 350ms ease 0"}],"stepsB":[{"opacity":0,"height":"0px","transition":"height 100ms ease 0, opacity 100ms ease 0"}]},{"type":"dropdown","selector":".dd-link-2","stepsA":[{"wait":"50ms"},{"opacity":1,"transition":"opacity 350ms ease 0"}],"stepsB":[{"opacity":0,"transition":"opacity 100ms ease 0"}]}]}},
	{"slug":"slide-reveal","name":"Slide Reveal","value":{"style":{"opacity":0,"x":"300px","y":"0px","z":"0px"},"triggers":[{"type":"slider","stepsA":[{"opacity":1,"transition":"transform 700ms ease-out-quad 0, opacity 900ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[{"wait":"1000ms"},{"opacity":0,"x":"300px","y":"0px","z":"0px"}]}]}},
	{"slug":"slide-header-reveal","name":"Slide Header Reveal","value":{"style":{"opacity":0,"x":"300px","y":"0px","z":"0px"},"triggers":[{"type":"slider","stepsA":[{"wait":"150ms"},{"opacity":1,"transition":"transform 700ms ease-out-quad 0, opacity 900ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[{"wait":"1000ms"},{"opacity":0,"x":"300px","y":"0px","z":"0px"}]}]}},
	{"slug":"slide-caption-reveal","name":"Slide Caption Reveal","value":{"style":{"opacity":0,"x":"300px","y":"0px","z":"0px"},"triggers":[{"type":"slider","stepsA":[{"wait":"250ms"},{"opacity":1,"transition":"transform 700ms ease-out-quad 0, opacity 900ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[{"wait":"1000ms"},{"opacity":0,"x":"300px","y":"0px","z":"0px"}]}]}},
	{"slug":"reveal-all-flights","name":"Reveal All Flights","value":{"style":{},"triggers":[{"type":"hover","selector":".find-flight-dd","stepsA":[{"opacity":1,"height":"auto","transition":"height 200 ease 0, opacity 200 ease 0"}],"stepsB":[{"wait":"2000ms"},{"opacity":0,"height":"0px","transition":"opacity 200 ease 0, height 200 ease 0"}]}]}},
	{"slug":"reveal-all-flights-2","name":"Reveal All Flights 2","value":{"style":{},"triggers":[{"type":"click","selector":".flight-results-outer-wrap","stepsA":[{"wait":"500ms"},{"display":"block"},{"opacity":0,"height":"0px"},{"opacity":1,"height":"auto","transition":"height 200 ease 0, opacity 200 ease 0"}],"stepsB":[]}]}},
	{"slug":"hover-out-flight-tool","name":"Hover Out Flight Tool","value":{"style":{},"triggers":[{"type":"hover","selector":".find-flight-dd","stepsA":[],"stepsB":[{"opacity":0,"height":"0px","transition":"height 200 ease 0, opacity 200 ease 0"}]},{"type":"hover","selector":".flight-results-outer-wrap","stepsA":[],"stepsB":[{"opacity":0,"height":"0px","transition":"height 200 ease 0, opacity 200 ease 0"},{"wait":"200ms"},{"display":"none"}]}]}},
	{"slug":"hover-out-flight-tool-2","name":"Hover Out Flight Tool 2","value":{"style":{},"triggers":[{"type":"hover","selector":".mini-flight-results-outer-wrap","stepsA":[],"stepsB":[{"opacity":0,"height":"0px","transition":"height 200 ease 0, opacity 200 ease 0"},{"wait":"200ms"},{"display":"none"}]}]}},
	{"slug":"search-dd-smooth-open","name":"Search DD Smooth Open","value":{"style":{},"triggers":[{"type":"dropdown","stepsA":[{"opacity":0,"height":"0px"},{"opacity":1,"height":"auto","transition":"opacity 350ms ease 0, height 350ms ease 0"}],"stepsB":[{"height":"0px","transition":"height 100ms ease 0, opacity 100ms ease 0"}]}]}},
	{"slug":"language-toggle","name":"Language Toggle","value":{"style":{},"triggers":[{"type":"click","selector":".english","stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]},{"type":"click","selector":".login","stepsA":[{"display":"none"}],"stepsB":[{"display":"block"}]}]}},
	{"slug":"search-cover","name":"Search Cover","value":{"style":{},"triggers":[{"type":"click","selector":".search-container-cover","stepsA":[{"height":"100vh","transition":"height 300ms ease 0"}],"stepsB":[]}]}},
	{"slug":"close-search","name":"Close Search","value":{"style":{},"triggers":[{"type":"click","selector":".search-container-cover","stepsA":[{"height":"0%","transition":"height 300ms ease 0"}],"stepsB":[]}]}},
	{"slug":"insta-load","name":"Insta Load","value":{"style":{"opacity":0,"x":"0px","y":"100px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"opacity":1,"transition":"transform 550ms ease-out-quad 0, opacity 650ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"insta-load-2","name":"Insta Load 2","value":{"style":{"opacity":0,"x":"0px","y":"100px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"wait":"100ms"},{"opacity":1,"transition":"transform 550ms ease-out-quad 0, opacity 650ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"insta-load-3","name":"Insta Load 3","value":{"style":{"opacity":0,"x":"0px","y":"100px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"wait":"200ms"},{"opacity":1,"transition":"transform 550ms ease-out-quad 0, opacity 650ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"row-2-cb-1-reveal","name":"Row 2 CB 1 Reveal","value":{"style":{"opacity":0,"x":"0px","y":"100px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"10%","stepsA":[{"opacity":1,"transition":"transform 550ms ease-out-quad 0, opacity 650ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"row-2-cb2-reveal","name":"Row 2 CB2 Reveal ","value":{"style":{"opacity":0,"x":"0px","y":"100px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"10%","stepsA":[{"opacity":1,"transition":"transform 550ms ease-out-quad 0, opacity 650ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"cb2-reveal","name":"CB2 Reveal","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","preload":true,"stepsA":[{"wait":"1000ms"},{"opacity":1,"transition":"transform 750ms ease 0, opacity 500ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"ken-burnsslideshow","name":"Ken BurnsSlideshow","value":{"style":{},"triggers":[{"type":"slider","loopA":true,"loopB":true,"stepsA":[{"wait":"15000ms","transition":"transform 15000ms ease 0","scaleX":1.2,"scaleY":1.2,"scaleZ":1,"x":"0px","y":"0px","z":"0px"}],"stepsB":[{"wait":"20000ms","transition":"transform 20000ms ease 0","scaleX":1,"scaleY":1,"scaleZ":1}]}]}},
	{"slug":"dropdown-hover","name":"Dropdown Hover","value":{"style":{},"triggers":[{"type":"hover","selector":".dropdown-list","descend":true,"stepsA":[{"display":"block","opacity":1,"height":"auto","transition":"opacity 300ms ease 0, height 300ms ease 0"}],"stepsB":[{"display":"none","opacity":0,"height":"0px","transition":"opacity 300ms ease 0ms, height 300ms ease 0ms"}]},{"type":"hover","selector":".nav-dd-triangle","descend":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
	{"slug":"profile-hover-dd","name":"Profile Hover DD","value":{"style":{},"triggers":[{"type":"hover","selector":".profile-dd-list","stepsA":[{"display":"block","opacity":1,"height":"auto","transition":"opacity 300ms ease 0, height 300ms ease 0"}],"stepsB":[{"opacity":0,"height":"0px","transition":"opacity 300ms ease 0ms, height 300ms ease 0ms"},{"wait":"300ms"},{"display":"none"}]},{"type":"hover","selector":".nav-dd-triangle","descend":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
	{"slug":"feature-slide-stage","name":"Feature Slide Stage","value":{"style":{"scaleX":0.9400000000000006,"scaleY":0.9400000000000006,"scaleZ":1},"triggers":[{"type":"slider","stepsA":[{"opacity":1,"transition":"transform 300ms ease 0, opacity 300ms ease 0","scaleX":1,"scaleY":1,"scaleZ":1}],"stepsB":[{"opacity":0.5,"transition":"transform 300ms ease 0, opacity 300ms ease 0","scaleX":0.9400000000000006,"scaleY":0.9400000000000006,"scaleZ":1}]}]}},
	{"slug":"feature-image","name":"Feature Image","value":{"style":{"opacity":0.5,"scaleX":1.5,"scaleY":1.5,"scaleZ":1},"triggers":[{"type":"slider","stepsA":[{"wait":"500ms"},{"opacity":1,"transition":"transform 2000ms ease-out-circ 0, opacity 200 ease 0","scaleX":1,"scaleY":1,"scaleZ":1}],"stepsB":[{"transition":"transform 300ms ease 0","scaleX":1.5,"scaleY":1.5,"scaleZ":1}]}]}},
	{"slug":"spinner","name":"Spinner","value":{"style":{},"triggers":[{"type":"click","selector":".spinner-country-wrap","preserve3d":true,"stepsA":[{"transition":"transform 50ms ease 0","rotateX":"60deg","rotateY":"0deg","rotateZ":"0deg"},{"transition":"transform 50ms ease 0","rotateX":"120deg","rotateY":"0deg","rotateZ":"0deg"},{"transition":"transform 50ms ease 0","rotateX":"0deg","rotateY":"0deg","rotateZ":"0deg"},{"transition":"transform 50ms ease 0","rotateX":"60deg","rotateY":"0deg","rotateZ":"0deg"},{"transition":"transform 50ms ease 0","rotateX":"120deg","rotateY":"0deg","rotateZ":"0deg"},{"transition":"transform 50ms ease 0","rotateX":"0deg","rotateY":"0deg","rotateZ":"0deg"},{"transition":"transform 50ms ease 0","rotateX":"60deg","rotateY":"0deg","rotateZ":"0deg"},{"transition":"transform 50ms ease 0","rotateX":"120deg","rotateY":"0deg","rotateZ":"0deg"},{"transition":"transform 50ms ease 0","rotateX":"0deg","rotateY":"0deg","rotateZ":"0deg"}],"stepsB":[]},{"type":"click","selector":".spinner-destination","stepsA":[{"opacity":0,"transition":"opacity 200 ease 0"},{"wait":"400ms"},{"opacity":1,"transition":"opacity 200 ease 0"}],"stepsB":[]}]}},
	{"slug":"tabarrow","name":"TabArrow","value":{"style":{},"triggers":[{"type":"click","selector":".tab-triangle","stepsA":[{"opacity":1}],"stepsB":[]}]}},
	{"slug":"tab-arrow-current","name":"Tab Arrow Current","value":{"style":{},"triggers":[{"type":"click","selector":".tab-triangle","descend":true,"stepsA":[{"opacity":1,"transition":"opacity 200 ease 0"}],"stepsB":[]}]}},
	{"slug":"zoom-in-slide","name":"Zoom In Slide","value":{"style":{"opacity":0,"x":"0px","y":"-20px","z":"0px","scaleX":0.5,"scaleY":0.5,"scaleZ":1},"triggers":[{"type":"slider","stepsA":[{"wait":"100ms"},{"opacity":1,"transition":"transform 600ms ease-out-back 0, opacity 500ms ease 0","x":"0px","y":"0px","z":"0px","scaleX":1,"scaleY":1,"scaleZ":1}],"stepsB":[{"opacity":0,"transition":"transform 200 ease 0, opacity 200 ease 0","x":"0px","y":"-20px","z":"0px","scaleX":0.5,"scaleY":0.5,"scaleZ":1}]}]}},
	{"slug":"search-x-reveal","name":"Search X Reveal","value":{"style":{},"triggers":[{"type":"click","selector":".nav-search-button-icon","descend":true,"stepsA":[{"display":"none"}],"stepsB":[{"display":"block"}]},{"type":"click","selector":".nav-search-button-close-icon","descend":true,"stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
	{"slug":"quick-search-reveal","name":"Quick Search Reveal","value":{"style":{},"triggers":[{"type":"click","selector":".quick-search-results-outer","stepsA":[{"wait":"1500ms"},{"opacity":1,"height":"auto","transition":"height 200 ease 0, opacity 200 ease 0"}],"stepsB":[]}]}},
	{"slug":"new-dropdown","name":"New Dropdown","value":{"style":{},"triggers":[{"type":"dropdown","stepsA":[{"opacity":0,"height":"0px"},{"opacity":1,"height":"auto","transition":"height 300ms ease 0, opacity 300ms ease 0"}],"stepsB":[{"height":"0px","transition":"height 100ms ease 0"}]}]}},
	{"slug":"more-options","name":"More Options","value":{"style":{},"triggers":[{"type":"click","stepsA":[{"display":"none","transition":"transform 300ms ease 0"}],"stepsB":[]},{"type":"click","selector":".less-options-button","stepsA":[{"display":"block","transition":"transform 300ms ease 0"}],"stepsB":[]},{"type":"click","selector":".locations-options-wrapper","stepsA":[{"display":"block","transition":"transform 300ms ease 0"}],"stepsB":[]}]}},
	{"slug":"hide-location-options","name":"Hide Location Options","value":{"style":{"display":"none"},"triggers":[]}},
	{"slug":"less-options","name":"Less Options","value":{"style":{"display":"none"},"triggers":[{"type":"click","stepsA":[{"display":"none","transition":"transform 300ms ease 0"}],"stepsB":[]},{"type":"click","selector":".more-options-button","stepsA":[{"display":"block","transition":"transform 300ms ease 0"}],"stepsB":[]},{"type":"click","selector":".locations-options-wrapper","stepsA":[{"display":"none","transition":"transform 300ms ease 0"}],"stepsB":[]}]}},
	{"slug":"add-nav-bar-shadow-on-scroll","name":"Add Nav Bar Shadow On Scroll","value":{"style":{},"triggers":[]}},
	{"slug":"menu-card-hover","name":"Menu Card Hover","value":{"style":{},"triggers":[{"type":"hover","selector":".feature-card-bg-image","preserve3d":true,"stepsA":[{"transition":"transform 300ms ease 0","scaleX":1.05,"scaleY":1.05,"scaleZ":1}],"stepsB":[{"transition":"transform 300ms ease 0","scaleX":1,"scaleY":1,"scaleZ":1}]},{"type":"hover","selector":".feature-card-heading","preserve3d":true,"stepsA":[{"transition":"transform 300ms ease 0","scaleX":1.05,"scaleY":1.05,"scaleZ":1}],"stepsB":[{"transition":"transform 300ms ease 0","scaleX":1,"scaleY":1,"scaleZ":1}]}]}},
	{"slug":"cb-image-hover","name":"CB Image Hover","value":{"style":{},"triggers":[{"type":"hover","stepsA":[{"transition":"transform 500ms ease 0","scaleX":1.03,"scaleY":1.03,"scaleZ":1}],"stepsB":[{"transition":"transform 500ms ease 0","scaleX":1,"scaleY":1,"scaleZ":1}]}]}},
	{"slug":"slide-caption-load","name":"Slide Caption Load","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"opacity":1,"transition":"transform 750ms ease 0, opacity 500ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"cb1-reveal","name":"CB1 Reveal","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","preload":true,"offsetBot":"10%","stepsA":[{"opacity":1,"transition":"transform 750ms ease 0, opacity 500ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"cb1-reveal-2","name":"CB1 Reveal 2","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","preload":true,"offsetBot":"10%","stepsA":[{"wait":"150ms"},{"opacity":1,"transition":"transform 750ms ease 0, opacity 500ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"cb1-reveal-3","name":"CB1 Reveal 3","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","preload":true,"offsetBot":"10%","stepsA":[{"wait":"150ms"},{"opacity":1,"transition":"transform 750ms ease 0, opacity 500ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"card-1-reveal","name":"Card 1 Reveal","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"15%","stepsA":[{"opacity":1,"transition":"transform 500ms ease 0, opacity 400ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"card-2-reveal","name":"Card 2 Reveal","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"15%","stepsA":[{"wait":"250ms"},{"opacity":1,"transition":"transform 500ms ease 0, opacity 400ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"card-3-reveal","name":"Card 3 Reveal","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"10%","stepsA":[{"wait":"500ms"},{"opacity":1,"transition":"transform 500ms ease 0, opacity 400ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"card-4-reveal","name":"Card 4 Reveal","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"10%","stepsA":[{"wait":"750ms"},{"opacity":1,"transition":"transform 500ms ease 0, opacity 400ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"lower-heading-reveal","name":"Lower Heading Reveal","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"10%","stepsA":[{"opacity":1,"transition":"transform 500ms ease 0, opacity 400ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"tab1-reveal","name":"Tab1 Reveal","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"10%","stepsA":[{"opacity":1,"transition":"transform 500ms ease 0, opacity 400ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"tab1-reveal-2","name":"Tab1 Reveal 2","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"10%","stepsA":[{"wait":"250ms"},{"opacity":1,"transition":"transform 500ms ease 0, opacity 400ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"tab1-reveal-3","name":"Tab1 Reveal 3","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"10%","stepsA":[{"wait":"500ms"},{"opacity":1,"transition":"transform 500ms ease 0, opacity 400ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"tab1-reveal-4","name":"Tab1 Reveal 4","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"10%","stepsA":[{"wait":"750ms"},{"opacity":1,"transition":"transform 500ms ease 0, opacity 400ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"tab1-reveal-5","name":"Tab1 Reveal 5","value":{"style":{"opacity":0,"x":"0px","y":"50px","z":"0px"},"triggers":[{"type":"scroll","offsetBot":"10%","stepsA":[{"wait":"1000ms"},{"opacity":1,"transition":"transform 500ms ease 0, opacity 400ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"close-alert","name":"Close Alert","value":{"style":{},"triggers":[{"type":"click","selector":".alert-wrapper-2","stepsA":[{"display":"none"}],"stepsB":[]},{"type":"click","selector":".spacer","stepsA":[{"display":"none"}],"stepsB":[]}]}},
	{"slug":"feature-text","name":"Feature Text","value":{"style":{"opacity":0,"x":"-40px","y":"0px","z":"0px"},"triggers":[{"type":"slider","stepsA":[{"wait":"1000ms"},{"opacity":1,"transition":"transform 500ms ease 0, opacity 500ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[{"opacity":0,"transition":"opacity 300ms ease 0"},{"transition":"transform 200 ease 0","x":"-40px","y":"0px","z":"0px"}]}]}},
	{"slug":"hide-mobile-header-on-load","name":"Hide Mobile Header On Load","value":{"style":{"height":"0px"},"triggers":[]}},
	{"slug":"expand-search-bar","name":"Expand Search Bar","value":{"style":{},"triggers":[]}},
	{"slug":"minislideshow-load","name":"MiniSlideshow Load","value":{"style":{"opacity":0,"x":"0px","y":"200px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"wait":"450ms"},{"opacity":1,"transition":"transform 1000ms ease-in-out 0, opacity 1000ms ease-in-out 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"intro-load","name":"Intro Load","value":{"style":{"opacity":0,"x":"0px","y":"200px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"wait":"450ms"},{"opacity":1,"transition":"transform 1250ms ease 0, opacity 1500ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
	{"slug":"keep-find-flight-dd-open","name":"Keep Find Flight DD Open","value":{"style":{},"triggers":[{"type":"hover","stepsA":[{"opacity":1,"height":"auto"}],"stepsB":[]}]}},
	{"slug":"reveal-view-all-flights-3","name":"Reveal View All Flights 3","value":{"style":{},"triggers":[{"type":"click","selector":".mini-flight-results-outer-wrap","stepsA":[{"wait":"500ms"},{"display":"block"},{"opacity":0,"height":"0px"},{"opacity":1,"height":"auto","transition":"height 200 ease 0, opacity 200 ease 0"}],"stepsB":[]}]}}
	]);
	/* eslint-enable */
});


/* Webflow code */
! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);
